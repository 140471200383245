html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'avenir',sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.headline-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 15%;
  max-width: 90vw;
  width: 600px;
  padding: 1rem 2rem;
  z-index: 1;
}

.overlay-hyperlink {
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 100%);
}

h2 {
  line-height: 110%;
}